import { default as certificate4aPadd8lXeMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/account/certificate.vue?macro=true";
import { default as collectQx6tPyTuiQMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/account/collect.vue?macro=true";
import { default as couponghLDiTv8Y6Meta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/account/coupon.vue?macro=true";
import { default as courseV2dBT2bXSwMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/account/course.vue?macro=true";
import { default as examqfM6SqlRwnMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/account/exam.vue?macro=true";
import { default as generalexamGOT6fvRGqgMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/account/generalexam.vue?macro=true";
import { default as gradecw3UEWwfTdMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/account/grade.vue?macro=true";
import { default as homeworkPXH7wukJvqMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/account/homework.vue?macro=true";
import { default as infoFPLnSmasdpMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/account/info.vue?macro=true";
import { default as indexOTAz0L1jqpMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/account/message/index.vue?macro=true";
import { default as note2PFP3pM7fgMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/account/note.vue?macro=true";
import { default as index36jV4UrVgSMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/account/order/index.vue?macro=true";
import { default as paper742BzhRBYeMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/account/paper.vue?macro=true";
import { default as scoreyvlhH9CkBTMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/account/score.vue?macro=true";
import { default as thesisER8bmVkq8hMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/account/thesis.vue?macro=true";
import { default as aiSayPjqEqZ1d5qMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/ai/aiSay.vue?macro=true";
import { default as aiTalkuCvfuBGt4lMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/ai/aiTalk.vue?macro=true";
import { default as list35ddxgOI34Meta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/article/list.vue?macro=true";
import { default as viewI8VF4hfB7AMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/article/view.vue?macro=true";
import { default as audioan1NtxwFdoMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/course/audio.vue?macro=true";
import { default as combine7hn28xT6R0Meta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/course/combine.vue?macro=true";
import { default as graphicYTsDPf7PjiMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/course/graphic.vue?macro=true";
import { default as list0rVFuixneVMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/course/list.vue?macro=true";
import { default as livewLzz3ASeKOMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/course/live.vue?macro=true";
import { default as singleE53h29UDutMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/course/single.vue?macro=true";
import { default as subjectew0xf2KctDMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/course/subject.vue?macro=true";
import { default as topicWix0vT3KhrMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/course/topic.vue?macro=true";
import { default as videogIgkNwDZpwMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/course/video.vue?macro=true";
import { default as analysisQ1zw4N728mMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/exam/analysis.vue?macro=true";
import { default as emigratedamGdLgl2FGMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/exam/emigrated.vue?macro=true";
import { default as indexP2jPlEjBXfMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/exam/index.vue?macro=true";
import { default as listry96nI79hxMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/exam/list.vue?macro=true";
import { default as result1gFaUUCE4FMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/exam/result.vue?macro=true";
import { default as startExamw7kBhqoQbnMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/exam/startExam.vue?macro=true";
import { default as homeworkNnal2EsWniMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/grade/homework.vue?macro=true";
import { default as listjgSXL4lcN8Meta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/grade/list.vue?macro=true";
import { default as materialHZKM3VdzrlMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/grade/material.vue?macro=true";
import { default as practicefCu7p3OV0yMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/grade/practice.vue?macro=true";
import { default as viewHFELBsy5fNMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/grade/view.vue?macro=true";
import { default as index9oEqb6PZKXMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/index.vue?macro=true";
import { default as agreementodCY3UWttnMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/login/agreement.vue?macro=true";
import { default as indexUiNBwZNUmNMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/login/index.vue?macro=true";
import { default as childrenListMMd5BEqo0vMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/paper/childrenList.vue?macro=true";
import { default as emigrated1t04TKlox2Meta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/paper/emigrated.vue?macro=true";
import { default as indexSQHoLjvELDMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/paper/index.vue?macro=true";
import { default as listSj3Q7jyKFrMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/paper/list.vue?macro=true";
import { default as view8Kh2JdAlORMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/paper/view.vue?macro=true";
import { default as buyfSYl50vYCrMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/pay/buy.vue?macro=true";
import { default as paySuccessLQnE2s1rpxMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/pay/paySuccess.vue?macro=true";
import { default as analysisXvJaOuOMFiMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/practice/analysis.vue?macro=true";
import { default as dailyrHXuy19VpLMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/practice/daily.vue?macro=true";
import { default as dailyProblemmEJasWz0mAMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/practice/dailyProblem.vue?macro=true";
import { default as errorhEwXC3PQOuMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/practice/error.vue?macro=true";
import { default as errorAnalysisNBTsWsIkqQMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/practice/errorAnalysis.vue?macro=true";
import { default as errorProblemPRTTbsPp9TMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/practice/errorProblem.vue?macro=true";
import { default as errorResult10G9OERehtMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/practice/errorResult.vue?macro=true";
import { default as resultFWXy8zQbEIMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/practice/result.vue?macro=true";
import { default as startPracticeZZIYrtMpOqMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/practice/startPractice.vue?macro=true";
import { default as viewg0xgl3YZFRMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/practice/view.vue?macro=true";
import { default as indexLU9GcKqojkMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/register/index.vue?macro=true";
import { default as indexHxNDfbf2XRMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/resetpwd/index.vue?macro=true";
import { default as index7RpKkqtcrrMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/search/index.vue?macro=true";
import { default as indextUOUPVe7dWMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/settlement/index.vue?macro=true";
import { default as listNKmjz3AeZWMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/teacher/list.vue?macro=true";
import { default as viewfBkGSlrZy0Meta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/teacher/view.vue?macro=true";
import { default as indexHbmaUNtpwgMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/vip/index.vue?macro=true";
import { default as success0q1sHo5E4VMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/wx/success.vue?macro=true";
import { default as wx6ehDWd9ymBMeta } from "C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/wx/wx.vue?macro=true";
export default [
  {
    name: certificate4aPadd8lXeMeta?.name ?? "account-certificate",
    path: certificate4aPadd8lXeMeta?.path ?? "/account/certificate",
    meta: certificate4aPadd8lXeMeta || {},
    alias: certificate4aPadd8lXeMeta?.alias || [],
    redirect: certificate4aPadd8lXeMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/account/certificate.vue").then(m => m.default || m)
  },
  {
    name: collectQx6tPyTuiQMeta?.name ?? "account-collect",
    path: collectQx6tPyTuiQMeta?.path ?? "/account/collect",
    meta: collectQx6tPyTuiQMeta || {},
    alias: collectQx6tPyTuiQMeta?.alias || [],
    redirect: collectQx6tPyTuiQMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/account/collect.vue").then(m => m.default || m)
  },
  {
    name: couponghLDiTv8Y6Meta?.name ?? "account-coupon",
    path: couponghLDiTv8Y6Meta?.path ?? "/account/coupon",
    meta: couponghLDiTv8Y6Meta || {},
    alias: couponghLDiTv8Y6Meta?.alias || [],
    redirect: couponghLDiTv8Y6Meta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/account/coupon.vue").then(m => m.default || m)
  },
  {
    name: courseV2dBT2bXSwMeta?.name ?? "account-course",
    path: courseV2dBT2bXSwMeta?.path ?? "/account/course",
    meta: courseV2dBT2bXSwMeta || {},
    alias: courseV2dBT2bXSwMeta?.alias || [],
    redirect: courseV2dBT2bXSwMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/account/course.vue").then(m => m.default || m)
  },
  {
    name: examqfM6SqlRwnMeta?.name ?? "account-exam",
    path: examqfM6SqlRwnMeta?.path ?? "/account/exam",
    meta: examqfM6SqlRwnMeta || {},
    alias: examqfM6SqlRwnMeta?.alias || [],
    redirect: examqfM6SqlRwnMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/account/exam.vue").then(m => m.default || m)
  },
  {
    name: generalexamGOT6fvRGqgMeta?.name ?? "account-generalexam",
    path: generalexamGOT6fvRGqgMeta?.path ?? "/account/generalexam",
    meta: generalexamGOT6fvRGqgMeta || {},
    alias: generalexamGOT6fvRGqgMeta?.alias || [],
    redirect: generalexamGOT6fvRGqgMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/account/generalexam.vue").then(m => m.default || m)
  },
  {
    name: gradecw3UEWwfTdMeta?.name ?? "account-grade",
    path: gradecw3UEWwfTdMeta?.path ?? "/account/grade",
    meta: gradecw3UEWwfTdMeta || {},
    alias: gradecw3UEWwfTdMeta?.alias || [],
    redirect: gradecw3UEWwfTdMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/account/grade.vue").then(m => m.default || m)
  },
  {
    name: homeworkPXH7wukJvqMeta?.name ?? "account-homework",
    path: homeworkPXH7wukJvqMeta?.path ?? "/account/homework",
    meta: homeworkPXH7wukJvqMeta || {},
    alias: homeworkPXH7wukJvqMeta?.alias || [],
    redirect: homeworkPXH7wukJvqMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/account/homework.vue").then(m => m.default || m)
  },
  {
    name: infoFPLnSmasdpMeta?.name ?? "account-info",
    path: infoFPLnSmasdpMeta?.path ?? "/account/info",
    meta: infoFPLnSmasdpMeta || {},
    alias: infoFPLnSmasdpMeta?.alias || [],
    redirect: infoFPLnSmasdpMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/account/info.vue").then(m => m.default || m)
  },
  {
    name: indexOTAz0L1jqpMeta?.name ?? "account-message",
    path: indexOTAz0L1jqpMeta?.path ?? "/account/message",
    meta: indexOTAz0L1jqpMeta || {},
    alias: indexOTAz0L1jqpMeta?.alias || [],
    redirect: indexOTAz0L1jqpMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/account/message/index.vue").then(m => m.default || m)
  },
  {
    name: note2PFP3pM7fgMeta?.name ?? "account-note",
    path: note2PFP3pM7fgMeta?.path ?? "/account/note",
    meta: note2PFP3pM7fgMeta || {},
    alias: note2PFP3pM7fgMeta?.alias || [],
    redirect: note2PFP3pM7fgMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/account/note.vue").then(m => m.default || m)
  },
  {
    name: index36jV4UrVgSMeta?.name ?? "account-order",
    path: index36jV4UrVgSMeta?.path ?? "/account/order",
    meta: index36jV4UrVgSMeta || {},
    alias: index36jV4UrVgSMeta?.alias || [],
    redirect: index36jV4UrVgSMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/account/order/index.vue").then(m => m.default || m)
  },
  {
    name: paper742BzhRBYeMeta?.name ?? "account-paper",
    path: paper742BzhRBYeMeta?.path ?? "/account/paper",
    meta: paper742BzhRBYeMeta || {},
    alias: paper742BzhRBYeMeta?.alias || [],
    redirect: paper742BzhRBYeMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/account/paper.vue").then(m => m.default || m)
  },
  {
    name: scoreyvlhH9CkBTMeta?.name ?? "account-score",
    path: scoreyvlhH9CkBTMeta?.path ?? "/account/score",
    meta: scoreyvlhH9CkBTMeta || {},
    alias: scoreyvlhH9CkBTMeta?.alias || [],
    redirect: scoreyvlhH9CkBTMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/account/score.vue").then(m => m.default || m)
  },
  {
    name: thesisER8bmVkq8hMeta?.name ?? "account-thesis",
    path: thesisER8bmVkq8hMeta?.path ?? "/account/thesis",
    meta: thesisER8bmVkq8hMeta || {},
    alias: thesisER8bmVkq8hMeta?.alias || [],
    redirect: thesisER8bmVkq8hMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/account/thesis.vue").then(m => m.default || m)
  },
  {
    name: aiSayPjqEqZ1d5qMeta?.name ?? "ai-aiSay",
    path: aiSayPjqEqZ1d5qMeta?.path ?? "/ai/aiSay",
    meta: aiSayPjqEqZ1d5qMeta || {},
    alias: aiSayPjqEqZ1d5qMeta?.alias || [],
    redirect: aiSayPjqEqZ1d5qMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/ai/aiSay.vue").then(m => m.default || m)
  },
  {
    name: aiTalkuCvfuBGt4lMeta?.name ?? "ai-aiTalk",
    path: aiTalkuCvfuBGt4lMeta?.path ?? "/ai/aiTalk",
    meta: aiTalkuCvfuBGt4lMeta || {},
    alias: aiTalkuCvfuBGt4lMeta?.alias || [],
    redirect: aiTalkuCvfuBGt4lMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/ai/aiTalk.vue").then(m => m.default || m)
  },
  {
    name: list35ddxgOI34Meta?.name ?? "article-list",
    path: list35ddxgOI34Meta?.path ?? "/article/list",
    meta: list35ddxgOI34Meta || {},
    alias: list35ddxgOI34Meta?.alias || [],
    redirect: list35ddxgOI34Meta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/article/list.vue").then(m => m.default || m)
  },
  {
    name: viewI8VF4hfB7AMeta?.name ?? "article-view",
    path: viewI8VF4hfB7AMeta?.path ?? "/article/view",
    meta: viewI8VF4hfB7AMeta || {},
    alias: viewI8VF4hfB7AMeta?.alias || [],
    redirect: viewI8VF4hfB7AMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/article/view.vue").then(m => m.default || m)
  },
  {
    name: audioan1NtxwFdoMeta?.name ?? "course-audio",
    path: audioan1NtxwFdoMeta?.path ?? "/course/audio",
    meta: audioan1NtxwFdoMeta || {},
    alias: audioan1NtxwFdoMeta?.alias || [],
    redirect: audioan1NtxwFdoMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/course/audio.vue").then(m => m.default || m)
  },
  {
    name: combine7hn28xT6R0Meta?.name ?? "course-combine",
    path: combine7hn28xT6R0Meta?.path ?? "/course/combine",
    meta: combine7hn28xT6R0Meta || {},
    alias: combine7hn28xT6R0Meta?.alias || [],
    redirect: combine7hn28xT6R0Meta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/course/combine.vue").then(m => m.default || m)
  },
  {
    name: graphicYTsDPf7PjiMeta?.name ?? "course-graphic",
    path: graphicYTsDPf7PjiMeta?.path ?? "/course/graphic",
    meta: graphicYTsDPf7PjiMeta || {},
    alias: graphicYTsDPf7PjiMeta?.alias || [],
    redirect: graphicYTsDPf7PjiMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/course/graphic.vue").then(m => m.default || m)
  },
  {
    name: list0rVFuixneVMeta?.name ?? "course-list",
    path: list0rVFuixneVMeta?.path ?? "/course/list",
    meta: list0rVFuixneVMeta || {},
    alias: list0rVFuixneVMeta?.alias || [],
    redirect: list0rVFuixneVMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/course/list.vue").then(m => m.default || m)
  },
  {
    name: livewLzz3ASeKOMeta?.name ?? "course-live",
    path: livewLzz3ASeKOMeta?.path ?? "/course/live",
    meta: livewLzz3ASeKOMeta || {},
    alias: livewLzz3ASeKOMeta?.alias || [],
    redirect: livewLzz3ASeKOMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/course/live.vue").then(m => m.default || m)
  },
  {
    name: singleE53h29UDutMeta?.name ?? "course-single",
    path: singleE53h29UDutMeta?.path ?? "/course/single",
    meta: singleE53h29UDutMeta || {},
    alias: singleE53h29UDutMeta?.alias || [],
    redirect: singleE53h29UDutMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/course/single.vue").then(m => m.default || m)
  },
  {
    name: subjectew0xf2KctDMeta?.name ?? "course-subject",
    path: subjectew0xf2KctDMeta?.path ?? "/course/subject",
    meta: subjectew0xf2KctDMeta || {},
    alias: subjectew0xf2KctDMeta?.alias || [],
    redirect: subjectew0xf2KctDMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/course/subject.vue").then(m => m.default || m)
  },
  {
    name: topicWix0vT3KhrMeta?.name ?? "course-topic",
    path: topicWix0vT3KhrMeta?.path ?? "/course/topic",
    meta: topicWix0vT3KhrMeta || {},
    alias: topicWix0vT3KhrMeta?.alias || [],
    redirect: topicWix0vT3KhrMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/course/topic.vue").then(m => m.default || m)
  },
  {
    name: videogIgkNwDZpwMeta?.name ?? "course-video",
    path: videogIgkNwDZpwMeta?.path ?? "/course/video",
    meta: videogIgkNwDZpwMeta || {},
    alias: videogIgkNwDZpwMeta?.alias || [],
    redirect: videogIgkNwDZpwMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/course/video.vue").then(m => m.default || m)
  },
  {
    name: analysisQ1zw4N728mMeta?.name ?? "exam-analysis",
    path: analysisQ1zw4N728mMeta?.path ?? "/exam/analysis",
    meta: analysisQ1zw4N728mMeta || {},
    alias: analysisQ1zw4N728mMeta?.alias || [],
    redirect: analysisQ1zw4N728mMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/exam/analysis.vue").then(m => m.default || m)
  },
  {
    name: emigratedamGdLgl2FGMeta?.name ?? "exam-emigrated",
    path: emigratedamGdLgl2FGMeta?.path ?? "/exam/emigrated",
    meta: emigratedamGdLgl2FGMeta || {},
    alias: emigratedamGdLgl2FGMeta?.alias || [],
    redirect: emigratedamGdLgl2FGMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/exam/emigrated.vue").then(m => m.default || m)
  },
  {
    name: indexP2jPlEjBXfMeta?.name ?? "exam",
    path: indexP2jPlEjBXfMeta?.path ?? "/exam",
    meta: indexP2jPlEjBXfMeta || {},
    alias: indexP2jPlEjBXfMeta?.alias || [],
    redirect: indexP2jPlEjBXfMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/exam/index.vue").then(m => m.default || m)
  },
  {
    name: listry96nI79hxMeta?.name ?? "exam-list",
    path: listry96nI79hxMeta?.path ?? "/exam/list",
    meta: listry96nI79hxMeta || {},
    alias: listry96nI79hxMeta?.alias || [],
    redirect: listry96nI79hxMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/exam/list.vue").then(m => m.default || m)
  },
  {
    name: result1gFaUUCE4FMeta?.name ?? "exam-result",
    path: result1gFaUUCE4FMeta?.path ?? "/exam/result",
    meta: result1gFaUUCE4FMeta || {},
    alias: result1gFaUUCE4FMeta?.alias || [],
    redirect: result1gFaUUCE4FMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/exam/result.vue").then(m => m.default || m)
  },
  {
    name: startExamw7kBhqoQbnMeta?.name ?? "exam-startExam",
    path: startExamw7kBhqoQbnMeta?.path ?? "/exam/startExam",
    meta: startExamw7kBhqoQbnMeta || {},
    alias: startExamw7kBhqoQbnMeta?.alias || [],
    redirect: startExamw7kBhqoQbnMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/exam/startExam.vue").then(m => m.default || m)
  },
  {
    name: homeworkNnal2EsWniMeta?.name ?? "grade-homework",
    path: homeworkNnal2EsWniMeta?.path ?? "/grade/homework",
    meta: homeworkNnal2EsWniMeta || {},
    alias: homeworkNnal2EsWniMeta?.alias || [],
    redirect: homeworkNnal2EsWniMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/grade/homework.vue").then(m => m.default || m)
  },
  {
    name: listjgSXL4lcN8Meta?.name ?? "grade-list",
    path: listjgSXL4lcN8Meta?.path ?? "/grade/list",
    meta: listjgSXL4lcN8Meta || {},
    alias: listjgSXL4lcN8Meta?.alias || [],
    redirect: listjgSXL4lcN8Meta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/grade/list.vue").then(m => m.default || m)
  },
  {
    name: materialHZKM3VdzrlMeta?.name ?? "grade-material",
    path: materialHZKM3VdzrlMeta?.path ?? "/grade/material",
    meta: materialHZKM3VdzrlMeta || {},
    alias: materialHZKM3VdzrlMeta?.alias || [],
    redirect: materialHZKM3VdzrlMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/grade/material.vue").then(m => m.default || m)
  },
  {
    name: practicefCu7p3OV0yMeta?.name ?? "grade-practice",
    path: practicefCu7p3OV0yMeta?.path ?? "/grade/practice",
    meta: practicefCu7p3OV0yMeta || {},
    alias: practicefCu7p3OV0yMeta?.alias || [],
    redirect: practicefCu7p3OV0yMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/grade/practice.vue").then(m => m.default || m)
  },
  {
    name: viewHFELBsy5fNMeta?.name ?? "grade-view",
    path: viewHFELBsy5fNMeta?.path ?? "/grade/view",
    meta: viewHFELBsy5fNMeta || {},
    alias: viewHFELBsy5fNMeta?.alias || [],
    redirect: viewHFELBsy5fNMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/grade/view.vue").then(m => m.default || m)
  },
  {
    name: index9oEqb6PZKXMeta?.name ?? "index",
    path: index9oEqb6PZKXMeta?.path ?? "/",
    meta: index9oEqb6PZKXMeta || {},
    alias: index9oEqb6PZKXMeta?.alias || [],
    redirect: index9oEqb6PZKXMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: agreementodCY3UWttnMeta?.name ?? "login-agreement",
    path: agreementodCY3UWttnMeta?.path ?? "/login/agreement",
    meta: agreementodCY3UWttnMeta || {},
    alias: agreementodCY3UWttnMeta?.alias || [],
    redirect: agreementodCY3UWttnMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/login/agreement.vue").then(m => m.default || m)
  },
  {
    name: indexUiNBwZNUmNMeta?.name ?? "login",
    path: indexUiNBwZNUmNMeta?.path ?? "/login",
    meta: indexUiNBwZNUmNMeta || {},
    alias: indexUiNBwZNUmNMeta?.alias || [],
    redirect: indexUiNBwZNUmNMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: childrenListMMd5BEqo0vMeta?.name ?? "paper-childrenList",
    path: childrenListMMd5BEqo0vMeta?.path ?? "/paper/childrenList",
    meta: childrenListMMd5BEqo0vMeta || {},
    alias: childrenListMMd5BEqo0vMeta?.alias || [],
    redirect: childrenListMMd5BEqo0vMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/paper/childrenList.vue").then(m => m.default || m)
  },
  {
    name: emigrated1t04TKlox2Meta?.name ?? "paper-emigrated",
    path: emigrated1t04TKlox2Meta?.path ?? "/paper/emigrated",
    meta: emigrated1t04TKlox2Meta || {},
    alias: emigrated1t04TKlox2Meta?.alias || [],
    redirect: emigrated1t04TKlox2Meta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/paper/emigrated.vue").then(m => m.default || m)
  },
  {
    name: indexSQHoLjvELDMeta?.name ?? "paper",
    path: indexSQHoLjvELDMeta?.path ?? "/paper",
    meta: indexSQHoLjvELDMeta || {},
    alias: indexSQHoLjvELDMeta?.alias || [],
    redirect: indexSQHoLjvELDMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/paper/index.vue").then(m => m.default || m)
  },
  {
    name: listSj3Q7jyKFrMeta?.name ?? "paper-list",
    path: listSj3Q7jyKFrMeta?.path ?? "/paper/list",
    meta: listSj3Q7jyKFrMeta || {},
    alias: listSj3Q7jyKFrMeta?.alias || [],
    redirect: listSj3Q7jyKFrMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/paper/list.vue").then(m => m.default || m)
  },
  {
    name: view8Kh2JdAlORMeta?.name ?? "paper-view",
    path: view8Kh2JdAlORMeta?.path ?? "/paper/view",
    meta: view8Kh2JdAlORMeta || {},
    alias: view8Kh2JdAlORMeta?.alias || [],
    redirect: view8Kh2JdAlORMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/paper/view.vue").then(m => m.default || m)
  },
  {
    name: buyfSYl50vYCrMeta?.name ?? "pay-buy",
    path: buyfSYl50vYCrMeta?.path ?? "/pay/buy",
    meta: buyfSYl50vYCrMeta || {},
    alias: buyfSYl50vYCrMeta?.alias || [],
    redirect: buyfSYl50vYCrMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/pay/buy.vue").then(m => m.default || m)
  },
  {
    name: paySuccessLQnE2s1rpxMeta?.name ?? "pay-paySuccess",
    path: paySuccessLQnE2s1rpxMeta?.path ?? "/pay/paySuccess",
    meta: paySuccessLQnE2s1rpxMeta || {},
    alias: paySuccessLQnE2s1rpxMeta?.alias || [],
    redirect: paySuccessLQnE2s1rpxMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/pay/paySuccess.vue").then(m => m.default || m)
  },
  {
    name: analysisXvJaOuOMFiMeta?.name ?? "practice-analysis",
    path: analysisXvJaOuOMFiMeta?.path ?? "/practice/analysis",
    meta: analysisXvJaOuOMFiMeta || {},
    alias: analysisXvJaOuOMFiMeta?.alias || [],
    redirect: analysisXvJaOuOMFiMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/practice/analysis.vue").then(m => m.default || m)
  },
  {
    name: dailyrHXuy19VpLMeta?.name ?? "practice-daily",
    path: dailyrHXuy19VpLMeta?.path ?? "/practice/daily",
    meta: dailyrHXuy19VpLMeta || {},
    alias: dailyrHXuy19VpLMeta?.alias || [],
    redirect: dailyrHXuy19VpLMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/practice/daily.vue").then(m => m.default || m)
  },
  {
    name: dailyProblemmEJasWz0mAMeta?.name ?? "practice-dailyProblem",
    path: dailyProblemmEJasWz0mAMeta?.path ?? "/practice/dailyProblem",
    meta: dailyProblemmEJasWz0mAMeta || {},
    alias: dailyProblemmEJasWz0mAMeta?.alias || [],
    redirect: dailyProblemmEJasWz0mAMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/practice/dailyProblem.vue").then(m => m.default || m)
  },
  {
    name: errorhEwXC3PQOuMeta?.name ?? "practice-error",
    path: errorhEwXC3PQOuMeta?.path ?? "/practice/error",
    meta: errorhEwXC3PQOuMeta || {},
    alias: errorhEwXC3PQOuMeta?.alias || [],
    redirect: errorhEwXC3PQOuMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/practice/error.vue").then(m => m.default || m)
  },
  {
    name: errorAnalysisNBTsWsIkqQMeta?.name ?? "practice-errorAnalysis",
    path: errorAnalysisNBTsWsIkqQMeta?.path ?? "/practice/errorAnalysis",
    meta: errorAnalysisNBTsWsIkqQMeta || {},
    alias: errorAnalysisNBTsWsIkqQMeta?.alias || [],
    redirect: errorAnalysisNBTsWsIkqQMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/practice/errorAnalysis.vue").then(m => m.default || m)
  },
  {
    name: errorProblemPRTTbsPp9TMeta?.name ?? "practice-errorProblem",
    path: errorProblemPRTTbsPp9TMeta?.path ?? "/practice/errorProblem",
    meta: errorProblemPRTTbsPp9TMeta || {},
    alias: errorProblemPRTTbsPp9TMeta?.alias || [],
    redirect: errorProblemPRTTbsPp9TMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/practice/errorProblem.vue").then(m => m.default || m)
  },
  {
    name: errorResult10G9OERehtMeta?.name ?? "practice-errorResult",
    path: errorResult10G9OERehtMeta?.path ?? "/practice/errorResult",
    meta: errorResult10G9OERehtMeta || {},
    alias: errorResult10G9OERehtMeta?.alias || [],
    redirect: errorResult10G9OERehtMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/practice/errorResult.vue").then(m => m.default || m)
  },
  {
    name: resultFWXy8zQbEIMeta?.name ?? "practice-result",
    path: resultFWXy8zQbEIMeta?.path ?? "/practice/result",
    meta: resultFWXy8zQbEIMeta || {},
    alias: resultFWXy8zQbEIMeta?.alias || [],
    redirect: resultFWXy8zQbEIMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/practice/result.vue").then(m => m.default || m)
  },
  {
    name: startPracticeZZIYrtMpOqMeta?.name ?? "practice-startPractice",
    path: startPracticeZZIYrtMpOqMeta?.path ?? "/practice/startPractice",
    meta: startPracticeZZIYrtMpOqMeta || {},
    alias: startPracticeZZIYrtMpOqMeta?.alias || [],
    redirect: startPracticeZZIYrtMpOqMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/practice/startPractice.vue").then(m => m.default || m)
  },
  {
    name: viewg0xgl3YZFRMeta?.name ?? "practice-view",
    path: viewg0xgl3YZFRMeta?.path ?? "/practice/view",
    meta: viewg0xgl3YZFRMeta || {},
    alias: viewg0xgl3YZFRMeta?.alias || [],
    redirect: viewg0xgl3YZFRMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/practice/view.vue").then(m => m.default || m)
  },
  {
    name: indexLU9GcKqojkMeta?.name ?? "register",
    path: indexLU9GcKqojkMeta?.path ?? "/register",
    meta: indexLU9GcKqojkMeta || {},
    alias: indexLU9GcKqojkMeta?.alias || [],
    redirect: indexLU9GcKqojkMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexHxNDfbf2XRMeta?.name ?? "resetpwd",
    path: indexHxNDfbf2XRMeta?.path ?? "/resetpwd",
    meta: indexHxNDfbf2XRMeta || {},
    alias: indexHxNDfbf2XRMeta?.alias || [],
    redirect: indexHxNDfbf2XRMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/resetpwd/index.vue").then(m => m.default || m)
  },
  {
    name: index7RpKkqtcrrMeta?.name ?? "search",
    path: index7RpKkqtcrrMeta?.path ?? "/search",
    meta: index7RpKkqtcrrMeta || {},
    alias: index7RpKkqtcrrMeta?.alias || [],
    redirect: index7RpKkqtcrrMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indextUOUPVe7dWMeta?.name ?? "settlement",
    path: indextUOUPVe7dWMeta?.path ?? "/settlement",
    meta: indextUOUPVe7dWMeta || {},
    alias: indextUOUPVe7dWMeta?.alias || [],
    redirect: indextUOUPVe7dWMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/settlement/index.vue").then(m => m.default || m)
  },
  {
    name: listNKmjz3AeZWMeta?.name ?? "teacher-list",
    path: listNKmjz3AeZWMeta?.path ?? "/teacher/list",
    meta: listNKmjz3AeZWMeta || {},
    alias: listNKmjz3AeZWMeta?.alias || [],
    redirect: listNKmjz3AeZWMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/teacher/list.vue").then(m => m.default || m)
  },
  {
    name: viewfBkGSlrZy0Meta?.name ?? "teacher-view",
    path: viewfBkGSlrZy0Meta?.path ?? "/teacher/view",
    meta: viewfBkGSlrZy0Meta || {},
    alias: viewfBkGSlrZy0Meta?.alias || [],
    redirect: viewfBkGSlrZy0Meta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/teacher/view.vue").then(m => m.default || m)
  },
  {
    name: indexHbmaUNtpwgMeta?.name ?? "vip",
    path: indexHbmaUNtpwgMeta?.path ?? "/vip",
    meta: indexHbmaUNtpwgMeta || {},
    alias: indexHbmaUNtpwgMeta?.alias || [],
    redirect: indexHbmaUNtpwgMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/vip/index.vue").then(m => m.default || m)
  },
  {
    name: success0q1sHo5E4VMeta?.name ?? "wx-success",
    path: success0q1sHo5E4VMeta?.path ?? "/wx/success",
    meta: success0q1sHo5E4VMeta || {},
    alias: success0q1sHo5E4VMeta?.alias || [],
    redirect: success0q1sHo5E4VMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/wx/success.vue").then(m => m.default || m)
  },
  {
    name: wx6ehDWd9ymBMeta?.name ?? "wx-wx",
    path: wx6ehDWd9ymBMeta?.path ?? "/wx/wx",
    meta: wx6ehDWd9ymBMeta || {},
    alias: wx6ehDWd9ymBMeta?.alias || [],
    redirect: wx6ehDWd9ymBMeta?.redirect || undefined,
    component: () => import("C:/Users/Administrator/Desktop/交接文档以及代码/projectCode/web/pages/wx/wx.vue").then(m => m.default || m)
  }
]